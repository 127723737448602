import React from 'react';
import {Reference} from '@/types/creatorSafetyReport';
import useBrandSafetyReportBackgroundCheckQuery from '@/hooks/queries/use-brand-safety-report-background-check-query';
import {BackgroundCheckItemProps} from '../background-check-item/BackgroundCheckItem';

interface ProfileVettingBackgroundCheckReferenceDataWrapperProps {
  children: (item: BackgroundCheckItemProps) => React.ReactNode;
  creatorId: string;
  reportId: string;
  reference: Reference;
}

function ProfileVettingBackgroundCheckReferenceDataWrapper(
  props: ProfileVettingBackgroundCheckReferenceDataWrapperProps
) {
  const {children, creatorId, reportId, reference} = props;
  const {data: item} = useBrandSafetyReportBackgroundCheckQuery({
    creatorId,
    reportId,
    url: reference.url,
  });

  if (!item) {
    return null;
  }

  return children({
    siteIcon: item.iconUrl,
    siteName: item.site,
    date: item.date,
    title: item.title,
    thumbnailUrl: item.thumbnailUrl,
    url: reference.url,
    summary: reference.summary,
  });
}

export default ProfileVettingBackgroundCheckReferenceDataWrapper;
