import {useFlags} from 'launchdarkly-react-client-sdk';
import React, {useEffect, useState} from 'react';
import {Button, Modal} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import {CampaignProps} from '@/types/campaign';
import LAUNCH_CAMPAIGN_MODAL_TEST_IDS from '@/views/campaigns/brief/components/launch-campaign-modal/testIds';
import Link from '@/components/link';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useNavigation from '@/hooks/use-navigation';
import styles from './LaunchCampaignModal.module.scss';

const TRANSLATION_PREFIX = 'views.campaigns.components.launch-campaign-modal';

type LaunchCampaignModalProps = {
  campaignData: CampaignProps | null;
  brandId: string;
  onSubmit: (subscriptionType: string) => Promise<void>;
  onClose: () => void;
  showCampaignIsLive?: boolean;
  interactionId?: string;
  viewPresentationId?: string;
};

type ModalContentProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
  campaignId?: string;
  brandId?: string;
  isLoading?: boolean;
};

class ModalContent {
  static Shared = ({
    isLoading,
    containerClassName,
    title,
    secondaryTitle,
    subtitle,
    primaryAction,
    primaryButtonText,
    primaryButtonUrl,
    primaryButtonMode = 'filled',
    secondaryAction,
    secondaryButtonText,
    secondaryButtonUrl,
  }: {
    isLoading?: boolean;
    containerClassName?: string;
    title: string;
    secondaryTitle?: string;
    subtitle?: string;
    primaryAction?: () => void;
    primaryButtonText?: string;
    primaryButtonUrl?: string;
    primaryButtonMode?: 'filled' | 'tinted';
    secondaryAction?: () => void;
    secondaryButtonText?: string;
    secondaryButtonUrl?: string;
  }) => {
    const getPrimaryButton = () => {
      if (!primaryAction && !primaryButtonUrl && !primaryButtonText) {
        return null;
      }

      const primaryButton = (
        <Button
          testID={
            LAUNCH_CAMPAIGN_MODAL_TEST_IDS.LAUNCH_CAMPAIGN_MODAL__PRIMARY_BUTTON
          }
          isLoading={isLoading}
          disabled={isLoading}
          onClick={primaryAction}
          className={styles.primaryButton}
          appearance="neutral"
          mode={primaryButtonMode}
          size="large"
        >
          {primaryButtonText}
        </Button>
      );

      if (primaryButtonUrl) {
        return (
          <Link className={styles.primaryButton} to={primaryButtonUrl}>
            {primaryButton}
          </Link>
        );
      }

      return primaryButton;
    };

    const getSecondaryButton = () => {
      if (!secondaryButtonUrl && !secondaryButtonText && !secondaryAction) {
        return null;
      }

      return secondaryButtonUrl ? (
        <a
          className={styles.secondaryButton}
          href={secondaryButtonUrl}
          onClick={secondaryAction}
          target="_blank"
          rel="noreferrer"
        >
          {secondaryButtonText}
        </a>
      ) : (
        <span className={styles.secondaryButton} onClick={secondaryAction}>
          {secondaryButtonText}
        </span>
      );
    };

    return (
      <div className={`${styles.shared} ${containerClassName || ''}`}>
        <span className={styles.title}>{title}</span>
        {secondaryTitle ? (
          <span className={styles.secondaryTitle}>{secondaryTitle}</span>
        ) : null}
        {subtitle ? <span className={styles.subtitle}>{subtitle}</span> : null}
        {getPrimaryButton()}
        {getSecondaryButton()}
      </div>
    );
  };

  static CampaignIsLive = ({
    primaryAction,
    secondaryAction,
    campaignId,
    brandId,
    isLoading,
  }: ModalContentProps) => {
    const translationPrefix = `${TRANSLATION_PREFIX}.campaign-is-live`;

    return (
      <ModalContent.Shared
        title={translate(`${translationPrefix}.title`)}
        subtitle={translate(`${translationPrefix}.subtitle`)}
        primaryButtonText={translate(`${translationPrefix}.primary-button`)}
        primaryButtonUrl={`/campaigns/${campaignId}/candidates?brandId=${brandId}`}
      />
    );
  };

  static Free = ({
    primaryAction,
    secondaryAction,
    campaignId,
    brandId,
    isLoading,
  }: ModalContentProps) => {
    const translationPrefix = `${TRANSLATION_PREFIX}.free`;
    return (
      <ModalContent.Shared
        isLoading={isLoading}
        title={translate(`${translationPrefix}.title`)}
        subtitle={translate(`${translationPrefix}.subtitle`)}
        primaryAction={primaryAction}
        primaryButtonText={translate(`${translationPrefix}.primary-button`)}
        secondaryAction={secondaryAction}
        secondaryButtonText={translate(`${translationPrefix}.secondary-button`)}
      />
    );
  };

  static Essentials = ({
    primaryAction,
    secondaryAction,
    campaignId,
    brandId,
    isLoading,
  }: ModalContentProps) => {
    const translationPrefix = `${TRANSLATION_PREFIX}.essentials`;

    return (
      <ModalContent.Shared
        isLoading={isLoading}
        title={translate(`${translationPrefix}.title`)}
        subtitle={translate(`${translationPrefix}.subtitle`)}
        primaryAction={primaryAction}
        primaryButtonText={translate(`${translationPrefix}.primary-button`)}
        secondaryAction={secondaryAction}
        secondaryButtonText={translate(`${translationPrefix}.secondary-button`)}
      />
    );
  };

  static EnterpriseAndPlatform = ({
    primaryAction,
    secondaryAction,
    campaignId,
    brandId,
  }: ModalContentProps) => {
    const translationPrefix = `${TRANSLATION_PREFIX}.enterprise-and-platform`;
    return (
      <ModalContent.Shared
        containerClassName={styles.enterpriseAndPlatformContainer}
        title={translate(`${translationPrefix}.title`)}
        secondaryTitle={translate(`${translationPrefix}.secondary-title`)}
        subtitle={translate(`${translationPrefix}.subtitle`)}
        primaryAction={primaryAction}
        primaryButtonText={translate(`${translationPrefix}.primary-button`)}
        primaryButtonUrl={`/search?brandId=${brandId}`}
        primaryButtonMode="tinted"
        secondaryAction={secondaryAction}
        secondaryButtonText={translate(`${translationPrefix}.secondary-button`)}
        secondaryButtonUrl="https://popularpays.zendesk.com/hc/en-us/articles/13020745177236"
      />
    );
  };
}

function LaunchCampaignModal({
  campaignData,
  brandId,
  onSubmit,
  onClose,
  showCampaignIsLive,
  interactionId,
  viewPresentationId,
}: LaunchCampaignModalProps) {
  const navigation = useNavigation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {pricingUpdates} = useFlags();
  const {subscription} = useSubscriptionQuery({
    brandId,
  });
  const isFreeAndNoPricingUpdates = subscription?.isFree && !pricingUpdates;

  useEffect(() => {
    if (
      !isSubmitted &&
      !!campaignData &&
      subscription.loaded &&
      !subscription.isFree &&
      !subscription.isEssentials &&
      !showCampaignIsLive
    ) {
      launchCampaign();
    }
  }, [isSubmitted, campaignData, subscription.loaded]);

  const {imageUrl, id: campaignId} = campaignData || {};

  const launchCampaign = async () => {
    try {
      const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.CAMPAIGN_CREATION
      );

      AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_VIEW_INTERACTION, {
        flow_id: campaignCreationFlow.flow_id,
        interaction_type: 'click',
        interaction_name: 'modal_launch_campaign_clicked',
        campaign_id: campaignId,
        interaction_location: 'launch_campaign_modal',
        interaction_details: '',
        interaction_id: interactionId,
        view_presentation_id: viewPresentationId,
      });

      setIsSubmitting(true);
      await onSubmit(subscription.subscriptionType);
      setIsSubmitted(true);
      if (isFreeAndNoPricingUpdates) {
        onClose();
        // TODO: act upon redirectToCampaignAfterSubscription in the /upgrade route
        navigation.navigate(
          `/upgrade?brandId=${brandId}&redirectToCampaignAfterSubscription=${campaignId}`
        );
      } else {
        // Essentials/Enterprise/Platform subscriptions are navigated to the campaign brief page with the modal open
        let url = `/campaigns/${campaignId}/brief?brandId=${brandId}&showCampaignIsLiveModal=true`;
        if (!subscription.isEssentials) {
          url = `/campaigns/${campaignId}/brief/edit?brandId=${brandId}&showCampaignIsLiveModal=true&step=preview-and-submit`;
        }
        navigation.navigate(url);
      }
    } catch (e) {
      console.log('launch campaign error', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getContent = () => {
    if (!subscription.loaded) {
      return null;
    }

    if (showCampaignIsLive) {
      if (!subscription.isFree && !subscription.isEssentials) {
        return (
          <ModalContent.EnterpriseAndPlatform
            campaignId={campaignId}
            brandId={brandId}
            primaryAction={() => onClose()}
            secondaryAction={() => onClose()}
          />
        );
      }

      return (
        <ModalContent.CampaignIsLive
          campaignId={campaignId}
          brandId={brandId}
          primaryAction={() => onClose()}
          secondaryAction={() => onClose()}
        />
      );
    }

    if (isFreeAndNoPricingUpdates) {
      return (
        <ModalContent.Free
          campaignId={campaignId}
          brandId={brandId}
          primaryAction={() => launchCampaign()}
          secondaryAction={() => onClose()}
          isLoading={isSubmitting}
        />
      );
    }

    if (subscription.isEssentials || pricingUpdates) {
      if (isSubmitted) {
        return (
          <ModalContent.CampaignIsLive
            campaignId={campaignId}
            brandId={brandId}
            primaryAction={() => onClose()}
            secondaryAction={() => onClose()}
          />
        );
      }

      return (
        <ModalContent.Essentials
          campaignId={campaignId}
          brandId={brandId}
          primaryAction={() => launchCampaign()}
          secondaryAction={() => onClose()}
          isLoading={isSubmitting}
        />
      );
    }

    return null;
  };

  if (
    !subscription.isFree &&
    !subscription.isEssentials &&
    !showCampaignIsLive
  ) {
    return null;
  }

  return (
    <Modal
      testID={LAUNCH_CAMPAIGN_MODAL_TEST_IDS.LAUNCH_CAMPAIGN_MODAL}
      className={styles.modal}
      showCloseButton
      open={!!campaignData}
      handleClose={onClose}
      hideScrollbars
    >
      <div className={styles.modalContent}>
        <div className={styles.imageContainer}>
          <img src={imageUrl || ''} alt="campaign" />
        </div>
        {getContent()}
      </div>
    </Modal>
  );
}

export default LaunchCampaignModal;
