import {useFlags} from 'launchdarkly-react-client-sdk';
import {debounce} from 'lodash';
import React, {useMemo, useEffect, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import ScienceIcon from '@mui/icons-material/Science';
import {Skeleton as MuiSkeleton} from '@mui/material';
import {Avatar} from '@lightricks/react-design-system';
import Autocomplete from '@/components/autocomplete';
import EmberIframe from '@/components/ember-iframe';
import useBrandsQuery from '@/hooks/queries/use-brands-query';
import useAvatarImageOrInitials from '@/hooks/use-avatar-image-or-initials';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import styles from './BrandElement.module.scss';

type BrandElementProps = {
  testID?: string;
};

function BrandElementSkeleton() {
  return (
    <div className={styles.brandElementSkeleton}>
      <MuiSkeleton variant="rectangular" width={32} height={32} />
      <MuiSkeleton variant="rounded" width={100} height={25} />
    </div>
  );
}

// when changing brand => redirect to the base route in the new brand
const BRAND_CHANGE_REDIRECTIONS = [
  {matcher: '/campaigns', redirectToParams: {to: '/campaigns'}},
  {matcher: '/search', redirectToParams: {to: '/search'}},
  {matcher: '/creators', redirectToParams: {to: '/creators'}},
  {matcher: '/collaborations', redirectToParams: {to: '/collaborations'}},
  {matcher: '/partnerships', redirectToParams: {to: '/partnerships'}},
  {matcher: '/content-library', redirectToParams: {to: '/content-library'}},
  {
    matcher: '/stats',
    redirectToParams: {sendToEmberIframe: 'stats:brandChanged'},
  },
];

function useDebounced(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function BrandElement(props: BrandElementProps) {
  const {testID = 'brand-element'} = props;
  const location = useLocation();
  const navigation = useNavigation();
  const brandId = useBrandId();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounced(searchQuery, 300);
  const {internalOnlyFeatures} = useFlags();
  const {
    data: brandsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetched: isBrandsDataFetched,
  } = useBrandsQuery({
    brandId,
    query: debouncedSearchQuery,
    enabled:
      debouncedSearchQuery.length >= 3 || debouncedSearchQuery.length === 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [isBrandsDataFetched]);

  const brands = useMemo(() => brandsData || [], [brandsData]);

  const brandOptions = useMemo(() => {
    return brands.slice().map((brand: {id: string; displayName: string}) => ({
      ...brand,
      key: brand.id,
      value: brand.id,
      label: brand.displayName,
    }));
  }, [brands]);

  const selectedBrand = useMemo(
    () =>
      brands.find(
        (brand: {id: string}) => String(brand.id) === String(brandId)
      ),
    [brands, brandId]
  );

  const {imageUrl, initials} = useAvatarImageOrInitials(
    selectedBrand?.displayAvatarUrl || '',
    selectedBrand?.displayName || ''
  );

  if (brands.length < 1 && !isBrandsDataFetched) {
    return <BrandElementSkeleton />;
  }

  if (brandOptions.length <= 1 && debouncedSearchQuery.length === 0) {
    return (
      <div
        className={styles.brandElement}
        data-testid={`${testID}--brand-name`}
      >
        <Avatar
          className={styles.brandAvatar}
          size="sm"
          src={imageUrl}
          type={imageUrl ? 'picture' : 'initials'}
          initials={initials}
          variant="square"
          background="transparent"
        />
        <div className={styles.brandName}>{selectedBrand?.displayName}</div>
      </div>
    );
  }

  const onBrandChange = (event: any, selectedOption: any | null) => {
    setSearchQuery('');
    const redirect = BRAND_CHANGE_REDIRECTIONS.find((brandChangeRedirection) =>
      location.pathname.includes(brandChangeRedirection.matcher)
    )?.redirectToParams;

    if (redirect) {
      if (redirect.to) {
        navigation.navigate(`${redirect.to}?brandId=${selectedOption.id}`);
      } else if (redirect.sendToEmberIframe) {
        EmberIframe.send(redirect.sendToEmberIframe, selectedOption.id);
      }
    } else {
      searchParams.set('brandId', selectedOption.id);
      setSearchParams(searchParams, {replace: true});
    }
  };

  const handleInputChange = (event: any, value: string, reason: string) => {
    if (reason === 'clear') {
      setSearchQuery('');
    } else if (reason === 'input') {
      setSearchQuery(value);
    }
  };

  const handleRenderOption = (
    optionProps: any,
    option: any,
    baseStyle: string
  ) => {
    const classNames = [
      optionProps.className,
      baseStyle,
      styles.brandElementOption,
    ];

    return (
      <li {...optionProps} className={classNames.join(' ')} key={option.id}>
        <div className={styles.iconContainer}>
          {option.isTestBrand && (
            <ScienceIcon className={styles.testBrandIcon} fontSize="small" />
          )}
        </div>
        <span>{option.label}</span>
      </li>
    );
  };

  return (
    <div className={styles.brandElement}>
      <Avatar
        className={styles.brandAvatar}
        size="sm"
        src={imageUrl}
        type={imageUrl ? 'picture' : 'initials'}
        initials={initials}
        variant="square"
        background="transparent"
      />
      <Autocomplete
        testID={`${testID}--brand-autocomplete`}
        className={styles.brandsAutocomplete}
        overrideDefaultPopperStyle
        customPopperStyle={styles.brandsAutocompletePopper}
        disableClearable
        adaptiveWidth
        showLabel={false}
        options={brandOptions}
        onChange={onBrandChange}
        onInputChange={handleInputChange}
        renderOption={internalOnlyFeatures ? handleRenderOption : undefined}
        selectedOptionId={selectedBrand?.id}
        slotProps={{popper: {placement: 'bottom-end'}}}
        value={selectedBrand?.displayName || ''}
      />
    </div>
  );
}

export default BrandElement;
