import {type VettableCreators} from '@/types/vettableCreatorsResponse';

const mockData: VettableCreators = {
  onPlatform: [
    {
      email: '',
      emails: null,
      id: '1',
      username: 'creator3',
      fullName: 'Creator 3',
    },
  ],
  offPlatform: [
    {
      email: '',
      emails: null,
      id: '2',
      username: 'creator1',
      fullName: 'Creator 1',
      provider: 'instagram',
    },
    {
      email: '',
      emails: null,
      id: '3',
      username: 'creator2',
      fullName: 'Creator 2',
      provider: 'tiktok',
    },
  ],
};

export const vettableCreatorsCombinable: VettableCreators = {
  onPlatform: [],
  offPlatform: [
    {
      id: 'UCYvmuw-JtVrTZQ-7Y4kd63Q',
      profileImage:
        'https://yt3.googleusercontent.com/Ig4z3PnNPni6nkGbsbEQV9FjdH7vxp4LtTya3IwFo6pPCdECwxgmBEep8h9U6oSKDdcBL0mlCTQ=s480-c-k-c0x00ffffff-no-rj',
      fullName: 'Katy Perry',
      email: null,
      emails: [],
      provider: 'youtube',
      username: 'KatyPerryMusic',
      relatedAccounts: [
        {
          provider: 'instagram',
          url: 'https://www.instagram.com/katyperry/',
        },
        {
          provider: 'tiktok',
          url: 'https://www.tiktok.com/share/user/131256363632148480',
        },
      ],
      accountUrl: 'https://www.youtube.com/channel/UCYvmuw-JtVrTZQ-7Y4kd63Q',
    },
    {
      id: '131256363632148480',
      profileImage:
        'https://imgigp.modash.io/v2?qeKgYKgV1gO1vAP42sjOAmt8VZOFcFEb4OJcMrjtna85Tjn5vdMMzB0%2FEXicr%2BY5dm8Ky3Qr2AYn6eDs46PyK43OR0UQcwws%2BIw0RJYthMVvhlCSIVUtPvCD0m60lS38NC6nN73AFLn0G91qLlOpDtDpqYuWOZD64E%2FiiRHz6Xk%3D',
      fullName: 'Katy Perry',
      email: null,
      emails: [],
      provider: 'tiktok',
      username: 'katyperry',
      relatedAccounts: [
        {
          provider: 'instagram',
          url: 'https://www.instagram.com/katyperry/',
        },
      ],
      accountUrl: 'https://www.tiktok.com/@katyperry',
    },
    {
      id: '407964088',
      profileImage:
        'https://imgigp.modash.io/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYEJpPTTZOdwDEdj3qpQOUiSEnPrWRCI2mIr4cvTFzUJLiDfxPbPXxIwTzz2MhkJZbHuciNgNG11ILiFyEZzIJMWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D',
      fullName: 'KATY PERRY',
      email: 'katheryninstagram@gmail.com',
      emails: ['katheryninstagram@gmail.com'],
      provider: 'instagram',
      username: 'katyperry',
      relatedAccounts: [
        {
          provider: 'youtube',
          url: 'https://www.youtube.com/channel/UCYvmuw-JtVrTZQ-7Y4kd63Q',
        },
        {
          provider: 'tiktok',
          url: 'https://www.tiktok.com/share/user/131256363632148480',
        },
      ],
      accountUrl: 'https://www.instagram.com/katyperry',
    },
  ],
};

export const vettableCreatorsUnique: VettableCreators = {
  onPlatform: [],
  offPlatform: [
    {
      id: 'UCU5BjmLIqW8Yv3z2FDpJj8Q',
      profileImage:
        'https://yt3.googleusercontent.com/ytc/AIdroLaOGnybrgZIt-4w-ig36v0OGxjijXDFtkETCEh-gMBC5g=s480-c-k-c0x00ffffff-no-rj',
      fullName: 'Foodie',
      email: null,
      ageGroup: '25-34',
      emails: [],
      provider: 'youtube',
      username: '',
      relatedAccounts: [],
      accountUrl: 'https://www.youtube.com/channel/UCU5BjmLIqW8Yv3z2FDpJj8Q',
    },
    {
      id: '6652704816537780225',
      profileImage:
        'https://imgigp.modash.io/v2?9gRRkBbg4nctjMDXek72QaD1Z6ujEs9fS8Aa10L%2B0D9PSnwGULyUDZKTdiD0Vp6pxpbk%2FPQaHD7NTqKqI9CLXrSM%2FuO005gSJWbWww8kfTP3o7tYLsGRdgKysuc8HK2Z%2FSP0%2FXQJ6eLrhBMUmM%2FOQA%3D%3D',
      fullName: 'Foodie',
      email: 'hello@foodie.my',
      emails: ['hello@foodie.my'],
      provider: 'tiktok',
      username: 'foodie',
      relatedAccounts: [],
      accountUrl: 'https://www.tiktok.com/@foodie',
    },
    {
      id: '1938541362',
      profileImage:
        'https://imgigp.modash.io/v2?c%2BZ6gMi8pzyyj3IdIuQSsDBpwchEsdg%2FtvYkoZ9FuoRcjNEJLZ53TNiAPrzxDUoWANwiZxk8X%2BByTDhLz9ICpnqPDKjX9kTSVZR5LJObSS7rI3%2FtDLfe9umFlCKtHO6R',
      fullName: 'Official Foodie',
      email: 'thefoodiegram@gmail.com',
      emails: ['thefoodiegram@gmail.com'],
      provider: 'instagram',
      username: 'foodie',
      relatedAccounts: [
        {
          provider: 'tiktok',
          url: 'https://www.tiktok.com/share/user/103190658667909120',
        },
      ],
      accountUrl: 'https://www.instagram.com/foodie',
    },
  ],
};

export default mockData;
