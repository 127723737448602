import React from 'react';
import {
  Avatar,
  Body,
  Headline,
  Icon,
  Label,
  designSystemToken,
} from '@lightricks/react-design-system';
import mockData from '@/mocks/brand-safety/mockVettableCreatorsResponse';
import translate from '@/utils/translate';
import {
  type VettableCreators,
  type VettableCreator,
} from '@/types/vettableCreatorsResponse';
import useCreateBrandSafetyVetting from '@/hooks/mutations/brand-safety/use-create-brand-safety-vetting';
import useCreateExternalProfile from '@/hooks/mutations/brand-safety/use-create-external-profile';
import useBrandId from '@/hooks/use-brand-id';
import {useBannerStore} from '@/contexts/BannerContext';
import trackBrandSafetyDetailEvent from '../../../utils/vettingHubAnalytics';
import CreatorSearchResult from './CreatorSearchResult';
import styles from './CreatorSearchResults.module.scss';

const HELP_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScgtRLy-AvrLawbJZhxcdTTk5dTbd4u-WNIT82t8RVAI9HdVA/viewform?usp=sf_link';

const EXTERNAL_MINOR_AGE_GROUP = '13-17';
const MINIMAL_INTERNAL_MINOR_AGE = 18;

const getAvatarSrc = (creator: VettableCreator) =>
  typeof creator.profileImage === 'string'
    ? creator.profileImage
    : creator.profileImage?.thumbJpg ?? creator.profileImage?.platforms?.[0];

type CreatorSearchResultsProps = {
  testID?: string;
  data?: VettableCreators;
  initialAddedCreators?: string[];
  onClose?: () => void;
};

function CreatorSearchResults(props: CreatorSearchResultsProps) {
  const {
    testID = 'creator-search-results',
    data = mockData,
    initialAddedCreators = [],
    onClose,
  } = props;

  const {dispatch} = useBannerStore();
  const brandId = useBrandId();
  const createExternalProfile = useCreateExternalProfile();
  const createBrandSafetyVetting = useCreateBrandSafetyVetting({brandId});

  const [addedCreators, setAddedCreators] =
    React.useState<VettableCreator['id'][]>(initialAddedCreators);

  const isExternalMinor = (ageGroup: string | null): boolean => {
    return ageGroup === null || ageGroup === EXTERNAL_MINOR_AGE_GROUP;
  };

  const isInternalMinor = (age: number | null): boolean => {
    return age === null || age < MINIMAL_INTERNAL_MINOR_AGE;
  };

  const {onPlatform, offPlatform} = React.useMemo(() => {
    const onPlatformWithIsMinor = data.onPlatform.map((creator) => ({
      ...creator,
      isMinor: isInternalMinor(creator.age ?? null),
    }));

    const offPlatformWithIsMinor = data.offPlatform.map((creator) => ({
      ...creator,
      isMinor: isExternalMinor(creator.ageGroup ?? null),
    }));

    return {
      onPlatform: onPlatformWithIsMinor,
      offPlatform: offPlatformWithIsMinor,
    };
  }, [data]);

  const handleVettingCreation = async (creator: VettableCreator) => {
    trackBrandSafetyDetailEvent({
      brand_safety_detail_name: 'Add Creator Clicked',
      brand_safety_detail_value: JSON.stringify(creator),
    });
    try {
      await createBrandSafetyVetting.mutateAsync({
        creator,
        brandId,
      });
      setAddedCreators([...addedCreators, creator.id]);

      const isOnPlatform = creator.accounts !== undefined;

      const isOnPlatformWithAccounts =
        isOnPlatform && creator.accounts!.length > 0;

      const username = isOnPlatform
        ? creator.accounts?.[0].username
        : creator.username;

      let title = '';
      let message = '';
      if (isOnPlatformWithAccounts) {
        title = translate('username-added-to-list', {
          username,
        });
        message = translate(
          "We're checking if they're eligible for vetting. If so, we'll update you ASAP. No need to stick around!"
        );
      } else if (isOnPlatform) {
        title = translate('invitation-sent');
        message = translate('sending-an-internal-creator-invite', {
          username,
        });
      } else {
        title = translate('invitation-sent');
        message = translate('sending-an-external-creator-invite', {
          username,
        });
      }

      const avatarSrc = getAvatarSrc(creator);

      dispatch({
        type: 'SET_BANNER' as const,
        title,
        message,
        icon: (
          <Avatar
            size="md"
            type={avatarSrc ? 'picture' : 'placeholder'}
            src={avatarSrc}
          />
        ),
      });

      onClose?.();
    } catch (e) {
      handleError(e);
    }
  };

  const handleError = (e: unknown) => {
    dispatch({
      type: 'SET_BANNER' as const,
      title: `${e}`,
      message: '',
      icon: (
        <Icon size="medium" appearance="danger" name="Actions-Alert-Line" />
      ),
    });
  };

  return (
    <div className={styles.container} data-testid={testID}>
      {onPlatform.length > 0 && (
        <Section
          testID="creator-search-results-on-platform"
          title={translate('found-on-popular-pays')}
          results={onPlatform}
          addedCreators={addedCreators}
          onClickAdd={(creator) => {
            handleVettingCreation(creator);
          }}
        />
      )}
      {offPlatform.length > 0 && (
        <Section
          isOffPlatform
          testID="creator-search-results-off-platform"
          title={translate('found-on-social-media')}
          addedCreators={addedCreators}
          results={offPlatform}
          onClickAdd={async (creator) => {
            if (!creator.username) {
              handleError(translate('creator-handle-not-found'));
              return;
            }

            const {profileId} = (await createExternalProfile.mutateAsync({
              brandId,
              handle: creator.username,
              network: creator.provider ?? '',
              email: creator.email ?? '',
              displayName: creator.fullName ?? '',
              profileImageUrl:
                typeof creator.profileImage === 'string'
                  ? creator.profileImage
                  : undefined,
              modashId: creator.id,
            })) as unknown as {profileId: string}; // TODO improve this typing

            handleVettingCreation({...creator, id: profileId});
          }}
        />
      )}
      {offPlatform.length === 0 && onPlatform.length === 0 && <EmptyState />}
      <Footer helpUrl={HELP_URL} />
    </div>
  );
}

type SectionProps = {
  testID?: string;
  title: string;
  results: VettableCreator[];
  addedCreators: string[];
  onClickAdd: (creator: VettableCreator) => void;
  isOffPlatform?: boolean;
};

function Section(props: SectionProps) {
  const {
    testID,
    title,
    results,
    addedCreators,
    onClickAdd,
    isOffPlatform = false,
  } = props;

  return (
    <div className={styles.section} data-testid={testID}>
      <Label size="md" color={designSystemToken('semantic.fg.secondary')}>
        {title}
      </Label>
      <div className={styles.sectionChildren}>
        {results.map((creator) => (
          <CreatorSearchResult
            key={creator.id}
            creator={creator}
            avatarSrc={getAvatarSrc(creator)}
            isAdded={addedCreators.includes(creator.id)}
            onClickAdd={onClickAdd}
            isOffPlatform={isOffPlatform}
          />
        ))}
      </div>
    </div>
  );
}

function Footer({helpUrl}: {helpUrl: string}) {
  return (
    <div className={styles.support} data-testid="creator-search-results-footer">
      <Body size="md">
        {translate('not-expected-creator')}{' '}
        <a href={helpUrl}>
          <Label
            size="md"
            color={designSystemToken('semantic.fg.secondary')}
            component="span"
          >
            {translate('get-help')}
          </Label>
        </a>
      </Body>
    </div>
  );
}

function EmptyState() {
  return (
    <div className={styles.emptyState}>
      <Headline size="sm">{translate('creator-not-found')}</Headline>
      <Body size="lg" color={designSystemToken('semantic.fg.secondary')}>
        {translate('no-search-result-for-handle')}
      </Body>
    </div>
  );
}

export default CreatorSearchResults;
