import {throttle} from 'lodash';
import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {useShallow} from 'zustand/react/shallow';
import {Headline} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import {VettableCreators} from '@/types/vettableCreatorsResponse';
import Page from '@/components/page';
import useCreatorBrandSafetyVetting from '@/hooks/queries/use-creator-brand-safety-vettings-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useVettableCreatorsQuery from '@/hooks/queries/use-vettable-creators-query';
import useBrandId from '@/hooks/use-brand-id';
import useVettingHubStore, {vettingHubActions} from '@/stores/vettingHubStore';
import styles from './VettingHub.module.scss';
import SearchSection from './components/search/search-section';
import VettingsTable from './components/vettings-table';
import trackBrandSafetyDetailEvent from './utils/vettingHubAnalytics';

type VettingHubProps = {
  tableData?: CreatorBrandSafetyVetting[];
};

function VettingHub(props: VettingHubProps) {
  const brandId = useBrandId();
  const {data: vettingsResponseData, isLoading} = useCreatorBrandSafetyVetting({
    brandId,
  });

  const pageScrollPosition = useVettingHubStore(
    useShallow((state) => state.pageScrollPosition)
  );

  const pageRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const animationFrame = requestAnimationFrame(() => {
      if (pageRef.current && pageScrollPosition) {
        pageRef.current.scrollTo({top: pageScrollPosition || 0});
      }
    });
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  useEffect(() => {
    if (!pageRef.current) {
      return () => {};
    }

    const onScroll = throttle((event: React.UIEvent<HTMLElement>) => {
      const {scrollTop} = event.target as HTMLDivElement;
      vettingHubActions.setPageScrollPosition(scrollTop);
    }, 500) as unknown as EventListener;

    pageRef.current.addEventListener('scroll', onScroll);

    return () => {
      if (pageRef.current) {
        pageRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, [pageRef]);

  useEffect(() => {
    const flow = AnalyticsService.createFlow(FLOW_NAMES.BRAND_SAFETY_CREATOR);
    AnalyticsService.dispatchEvent(eventNames.BRAND_SAFETY_FLOW_STARTED, {
      flow_id: flow.flow_id,
      screen_presentation_id: flow.screen_presentation_id,
      brand_safety_screen_name: 'Hub',
      source: 'in-platform',
    });
  }, []);

  const {subscription} = useSubscriptionQuery({
    brandId,
  });

  const {tableData = vettingsResponseData} = props;

  const [searchTerm, setSearchTerm] = React.useState<string | undefined>(
    undefined
  );

  const {data: searchResults, isFetching: isSearching} =
    useVettableCreatorsQuery({searchTerm});

  useEffect(() => {
    if (!searchResults) return;
    const payload = {
      brand_safety_detail_name: 'Search Results',
      brand_safety_detail_value: JSON.stringify(searchResults),
    };
    trackBrandSafetyDetailEvent(payload);
  }, [JSON.stringify(searchResults)]);

  const onSubmitSearch = (value: string) => {
    trackBrandSafetyDetailEvent({
      brand_safety_detail_name: 'Submit Search',
      brand_safety_detail_value: value,
    });
    setSearchTerm(value);
  };

  const onResetSearch = () => {
    setSearchTerm(undefined);
  };

  return (
    <Page
      id="vetting-hub"
      className={styles.page}
      childrenClassName={styles.vettingHubContainer}
      testID="vetting-hub"
      pageRef={pageRef}
    >
      <SearchSection
        darkMode={subscription.isSafeCollab}
        searchResults={searchResults as VettableCreators}
        onSubmitSearch={onSubmitSearch}
        onResetSearch={onResetSearch}
        isLoading={isSearching}
      />
      <Headline size="md" sx={{mt: 3}}>
        {translate('my-vetting-list')}
      </Headline>
      <VettingsTable data={tableData ?? []} isLoading={isLoading ?? false} />
    </Page>
  );
}

export default VettingHub;
