type FeatureFlagsMapping = {
  routes: {
    [routeName: string]: string;
  };
};

/**
 * This object maps various resources to feature flags names
 *
 * for example if we want to enable a feature flag for a route we can map it here and use the route name as the key
 * definition in mainRoutesList.ts
 * {
 *    name: 'search-creators',
 *    path: ROUTES.SEARCH,
 *    Component: SearchRoutes,
 * }
 *
 * usage in the code: retrieve the flag name from the mapping and check if it exists and is enabled
 * if it does not exist or is enabled we show the route
 *
 * MAIN_ROUTES_LIST.filter(({name}) => {
 *    const flagName = featureFlagsMapping.routes[name];
 *
 *    return flagName === undefined || flags[flagName] === true;
 * });
 */
const featureFlagsMapping: FeatureFlagsMapping = {
  routes: {
    'search-creators': 'searchCreators',
  },
};

export default featureFlagsMapping;
