import CREATORS_MOCK_1 from '@/mocks/brand-safety/creators_v3.json';
import CREATORS_MOCK_2 from '@/mocks/brand-safety/creators_v4.json';
import axiosWithAuth from '@/utils/axiosWithAuth';
import mapKeysToCase from '@/utils/mapKeysToCase';
import {CreatorSafetyReport} from '@/types/creatorSafetyReport';
import QueryContext from '../QueryContext';

const defaultCreatorId = '2';

const USE_MOCK = false;

const CREATORS_MOCK = {
  ...CREATORS_MOCK_1,
  ...CREATORS_MOCK_2,
};

const vettingFetchers = {
  report: (context: QueryContext) => {
    // TODO: remove this block and all mock related code
    if (USE_MOCK) {
      let creatorData = CREATORS_MOCK[defaultCreatorId];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      creatorData = CREATORS_MOCK[context.meta?.creatorId] || creatorData;
      return mapKeysToCase(creatorData) as unknown as CreatorSafetyReport;
    }
    return axiosWithAuth.get(
      `/creators/${context.meta?.creatorId}/brands_safety_reports/${context.meta?.reportId}`,
      {
        params: {
          brand_id: context.meta?.brandId,
        },
      }
    );
  },

  embedding: (context: QueryContext) =>
    axiosWithAuth.get(`/social_networks/${context.meta?.provider}/embeddings`, {
      params: {
        url: context.meta?.url,
      },
    }),
  backgroundCheck: (context: QueryContext) =>
    axiosWithAuth.get(
      `/creators/${context.meta?.creatorId}/brands_safety_reports/${context.meta?.reportId}/enrich_background_check`,
      {
        params: {
          url: context.meta?.url,
        },
      }
    ),
};

export default vettingFetchers;
