import clsx from 'clsx';
import React, {forwardRef, useState, useMemo} from 'react';
import translate from '@/utils/translate';
import EmberIframe from '@/components/ember-iframe';
import Link from '@/components/link';
import NewCampaignButton from '@/components/new-campaign-button';
import useIsMobile from '@/hooks/use-is-mobile';
import styles from './Menu.module.scss';
import MenuCollapseButton from './menu-collapse-button';
import MenuItem from './menu-item';
import menuItemStyles from './menu-item/MenuItem.module.scss';
import MENU_ITEMS from './menuItems';

export type MenuProps = {
  testID?: string;
  user: any;
  subscription: any;
  flags?: any;
  isCollapsed?: boolean;
  handleCollapse?: () => void;
};

function Menu(props: MenuProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    testID = 'menu',
    user: userData,
    subscription,
    flags = {},
    isCollapsed = false,
    handleCollapse,
  } = props;

  const {agency, currentBrand} = userData;
  const isMobile = useIsMobile();
  const [userOverrideProps, setUserOverrideProps] = useState({});

  const showCollapsed = useMemo(
    () => isCollapsed && !isMobile,
    [isCollapsed, isMobile]
  );

  EmberIframe.listen(
    'dashboardUserUpdated',
    async (dashboardUserProps: any) => {
      setUserOverrideProps(dashboardUserProps);
    }
  );

  const getWhitelabeledAgencyLink = () => {
    if (!agency?.isWhitelabeled) {
      return null;
    }

    return (
      <Link
        to="https://popularpays.com"
        target="_blank"
        rel="noopener noreferrer"
        keepBrandIdIfPresent={false}
        title={translate('components.menu.items.powered-by')}
        className={`${menuItemStyles.container} poppays`}
      />
    );
  };

  const onClickCollapse = () => {
    handleCollapse?.();
  };

  const containerClassName = clsx(
    styles.container,
    isCollapsed && styles['container--collapsed']
  );

  const menuItems = useMemo(
    () =>
      MENU_ITEMS.filter((menuItem) => {
        const isMobileLocationCorrect =
          !isMobile || menuItem.mobileLocation === 'main';
        const isFlagActive = !menuItem.flagName || flags[menuItem.flagName];
        return isMobileLocationCorrect && isFlagActive;
      }),
    [flags, isMobile]
  );

  const user = useMemo(
    () => ({
      ...(userData || {}),
      ...userOverrideProps,
    }),
    [userData, userOverrideProps]
  );

  const menuItemProps = useMemo(
    () => ({
      user,
      agency,
      subscription,
      flags,
      currentBrand,
      showLabel: !showCollapsed,
    }),
    [user, agency, subscription, currentBrand, showCollapsed, flags]
  );

  if (isMobile) {
    return (
      <div className={containerClassName} data-testid={testID} ref={ref}>
        <div className={styles.primary}>
          {menuItems.map((item) => (
            <MenuItem key={item.id} {...item} {...menuItemProps} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={containerClassName} data-testid={testID} ref={ref}>
      <div className={styles.primary}>
        <MenuCollapseButton
          onClickCollapse={onClickCollapse}
          isCollapsed={showCollapsed}
        />
        {menuItems.map((item) => (
          <MenuItem key={item.id} {...item} {...menuItemProps} />
        ))}
      </div>
      <div className={styles.secondary}>
        <NewCampaignButton
          hideLabel={showCollapsed}
          style={{
            alignSelf: 'end',
            width: showCollapsed ? undefined : '100%',
            padding: showCollapsed ? 0 : undefined,
          }}
        />
        {agency?.isWhitelabeled && <div>{getWhitelabeledAgencyLink()}</div>}
      </div>
    </div>
  );
}

const MenuWithRef = forwardRef(Menu);

export default MenuWithRef;
