import {QueryClientProvider} from '@tanstack/react-query';
import {ApiProvider} from 'jsonapi-react';
import {LDProvider, basicLogger} from 'launchdarkly-react-client-sdk';
import {capitalize} from 'lodash';
import React, {ReactNode} from 'react';
import {I18nextProvider} from 'react-i18next';
import {DesignSystemThemeProvider} from '@lightricks/react-design-system';
import {getEnv} from '@/config/environment';
import {BannerProvider} from '@/contexts/BannerContext';
import {CampaignBriefHelperProvider} from '@/contexts/CampaignBriefHelperContext';
import {ContentGenerationStoreProvider} from '@/contexts/ContentGenerationStore';
import ContextProviderComposer from '@/contexts/ContextProviderComposer';
import {NewCampaignFormDataProvider} from '@/contexts/NewCampaignContext';
import i18n from './config/locales';
import jsonApiClient from './utils/jsonapiClient';
import reactQueryClient from './utils/reactQueryClient';

function AppProviders({children}: {children: ReactNode}) {
  return (
    <LDProvider
      clientSideID={getEnv().VITE_LAUNCHDARKLY_CLIENTSIDE_ID}
      context={{key: 'anonymous-user', anonymous: true}}
      reactOptions={{useCamelCaseFlagKeys: true}}
      options={{
        application: {
          id: `AgencyWebApp-React-${capitalize(getEnv().MODE)}`,
          version: '1.0.0',
        },
        bootstrap: 'localStorage',
        logger: basicLogger({
          level: getEnv().MODE === 'test' ? 'none' : 'error',
        }),
      }}
    >
      <ApiProvider client={jsonApiClient}>
        <QueryClientProvider client={reactQueryClient}>
          <I18nextProvider i18n={i18n}>
            <DesignSystemThemeProvider theme="popularpayLight">
              <ContextProviderComposer
                contexts={[
                  BannerProvider,
                  ContentGenerationStoreProvider,
                  NewCampaignFormDataProvider,
                  CampaignBriefHelperProvider,
                ]}
              >
                {children}
              </ContextProviderComposer>
            </DesignSystemThemeProvider>
          </I18nextProvider>
        </QueryClientProvider>
      </ApiProvider>
    </LDProvider>
  );
}

export default AppProviders;
