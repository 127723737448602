import {useFlags, useLDClient} from 'launchdarkly-react-client-sdk';
import {useState, useEffect} from 'react';
import getCookieValue from '@/utils/getCookieValue';
import {getEnv} from '@/config/environment';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useToken from '@/hooks/use-token';

const LT_ID_COOKIE_NAME = getEnv().MODE === 'staging' ? 'stg__lt_id' : 'lt_id';

function useSetLaunchDarklyContext() {
  const {isAuthenticated, initialized: tokenInitialized} = useToken(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const flags = useFlags();
  const ldClient = useLDClient();
  const ltId = getCookieValue(LT_ID_COOKIE_NAME);

  const {dashboardUser}: any = useDashboardUserQuery({
    overrideEnabled: isAuthenticated,
    include: [],
  });
  useEffect(() => {
    if (!ldClient) {
      return;
    }

    if (
      isAuthenticated &&
      tokenInitialized &&
      dashboardUser?.email &&
      ltId &&
      ldClient?.getContext()?.key !== ltId
    ) {
      ldClient
        .identify({
          key: ltId,
          kind: 'user',
          name: dashboardUser?.displayName,
          first_name: dashboardUser?.firstName,
          last_name: dashboardUser?.lastName,
          email: dashboardUser?.email,
          ip: dashboardUser?.currentSignInIp,
        })
        .then(() => {
          setIsInitialized(true);
        });
    } else if (
      !isAuthenticated &&
      ldClient?.getContext()?.key !== 'anonymous-user'
    ) {
      ldClient.identify({key: 'anonymous-user', anonymous: true});
    }

    if (tokenInitialized && !isAuthenticated && !isInitialized) {
      setIsInitialized(true);
    }
  }, [
    isAuthenticated,
    tokenInitialized,
    dashboardUser,
    isInitialized,
    ltId,
    ldClient,
  ]);

  return {
    flags,
    launchDarklyInitialized: isInitialized,
  };
}

export default useSetLaunchDarklyContext;
