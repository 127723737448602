import Analytics, {eventNames} from '@popularpays/analytics';
import {getToken} from '@/utils/token';
import {getEnv} from '@/config/environment';

const brandsEventNames = eventNames.brands;

const AnalyticsService = new Analytics([
  {
    id: 'gtm',
    options: {
      gtmId: getEnv().VITE_GTM_API_KEY,
      appBundleId: 'brands',
    },
  },
  {
    id: 'delta',
    options: {
      appIdentifier: 'brands',
      appBundleId: 'brands',
      identifyIsLtxTeamUser: async () => {
        const token = await getToken();
        if (token?.authenticated?.email) {
          return token.authenticated.email.includes('lightricks');
        }

        return false;
      },
    },
  },
]);

export default AnalyticsService;
export {brandsEventNames as eventNames};
