import {useEffect} from 'react';

// extend the Window interface for TypeScript
declare global {
  interface Window {
    zE?: (command: string, ...args: any[]) => void;
  }
}

export const useZendeskScript = (key: string, featureFlag = false) => {
  useEffect(() => {
    if (!featureFlag) return () => {};
    const scriptId = 'ze-snippet';

    const addZendeskScript = () => {
      if (document.getElementById(scriptId)) return;

      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
      script.async = true;
      document.body.appendChild(script);
    };

    addZendeskScript();

    return () => {
      // use Zendesk's API to hide the widget if it's not needed when the component unmounts
      if (window.zE) {
        window.zE('messenger', 'hide');
      }

      // remove the script entirely
      const script = document.getElementById(scriptId);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [key, featureFlag]);
};

export default useZendeskScript;
