import dashboardUserFetchers from '@/api/fetchers/dashboardUser';
import useDataQuery from '@/hooks/use-data-query';
import useToken from '@/hooks/use-token';

function useDashboardUserQuery({
  enabled = true,
  overrideEnabled = false,
  include,
}: {
  enabled?: boolean;
  overrideEnabled?: boolean;
  include?: string[];
}) {
  const {isAuthenticated} = useToken(true, true);
  const queryKey = include ? ['dashboardUser', ...include] : ['dashboardUser'];

  if (overrideEnabled) {
    queryKey.push('overrideEnabled');
  }

  const response = useDataQuery({
    queryKey,
    queryFn: dashboardUserFetchers.dashboardUser,
    enabled: (isAuthenticated && enabled) || overrideEnabled,
    meta: {
      include,
    },
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const dashboardUser = (): any => {
    return response.data || {};
  };

  const hasUserRole = (name: string) => {
    const roles = dashboardUser()?.roles;
    if (!roles) {
      return false;
    }
    return !!roles.find((role: {name: string}) => role.name === name);
  };

  return {
    ...response,
    dashboardUser: {
      ...dashboardUser(),
      loaded: enabled && response.isFetched,
      hasPaymentManagerRole: hasUserRole('platform_payment_manager'),
      hasPlatformAdminRole: hasUserRole('platform_admin'),
    },
  };
}

export default useDashboardUserQuery;
